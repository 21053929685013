//
// Link utilities
//

.link-stretched {
  &::after {
    position: absolute;
    z-index: $zindex-stretched-link;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }
}
