//
// Variables
//

$modal-close-size: 2rem;

//
// Modal
//

.modal {
  position: fixed;
  z-index: $zindex-modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

.modal__dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal__content {
  position: relative;
  width: 100%;
  padding: 2rem;
  background-color: $white;
  box-shadow: 0 0.125rem 0.25rem rgba($black, 0.075);
  // Remove focus outline from opened modal
  outline: 0;
  pointer-events: auto;

  &--transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &--inverted {
    background-color: $gray-800;
    color: $white;

    .modal_close {
      color: $white;
    }
  }

  &--rounded {
    border-radius: 0.5rem;
  }
}

.modal__backdrop {
  position: fixed;
  z-index: $zindex-default-behind;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $black;
  opacity: 0.6;

  &--inverted {
    background-color: $gray-800;
    opacity: 0.25;
  }
}

.modal__close {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  width: $modal-close-size;
  height: $modal-close-size;
  padding: 1rem;
  opacity: 0.5;

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    opacity: 1;
  }
}

@include mq-up(sm) {
  .modal__dialog {
    &--default {
      margin: 3.5rem auto;
    }

    &--center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--top {
      position: absolute;
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &--bottom {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
    }

    &--xs {
      width: auto;
    }

    &--sm {
      width: 300px;
    }

    &--md {
      width: 500px;
    }

    &--lg {
      width: 800px;
    }

    &--xl {
      width: 1200px;
    }
  }
}
