.popper-arrow,
.popper-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

.popper-arrow {
  visibility: hidden;
}

.popper-arrow::before {
  content: '';
  transform: rotate(45deg);
  visibility: visible;
}

.popper[data-popper-placement^='top'] > .popper-arrow {
  bottom: -4px;
}

.popper[data-popper-placement^='bottom'] > .popper-arrow {
  top: -4px;
}

.popper[data-popper-placement^='left'] > .popper-arrow {
  right: -4px;
}

.popper[data-popper-placement^='right'] > .popper-arrow {
  left: -4px;
}
