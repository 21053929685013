//
// Breakpoints
//

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
// (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
// `breakpoint-min(sm) = 576px`
@function breakpoint-min($name) {
  $min: map-get($grid-breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Maximum breakpoint width. The maximum value is reduced by 0.02px.
// (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
// `breakpoint-max(md) = 767.98px`
@function breakpoint-max($name) {
  $max: map-get($grid-breakpoints, $name);
  @return if($max and $max > 0, $max - 0.02, null);
}

// Returns a blank string if smallest breakpoint, otherwise returns the name with a dash in front.
// `breakpoint-infix(sm) = -sm`
@function breakpoint-infix($name) {
  @return if(breakpoint-min($name) == null, '', '-#{$name}');
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin mq-up($name) {
  $min: breakpoint-min($name);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media of at most the maximum breakpoint width. No query for the largest breakpoint.
// Makes the @content apply to the given breakpoint and narrower.
@mixin mq-down($name) {
  $max: breakpoint-max($name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
