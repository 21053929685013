//
// Variables
//

$close-font-weight: $font-weight-bold;

//
// Close
//

.close {
  color: inherit;
  cursor: $cursor-pointer;
  float: right;
  font-size: $close-font-size;
  font-weight: $close-font-weight;
  line-height: 1;
  opacity: 0.5;

  &:hover,
  &:focus {
    opacity: 0.75;
    text-decoration: none;
  }
}

/* stylelint-disable selector-no-qualifying-type */
button.close {
  padding: 0;
  border: 0;
  appearance: none;
  background-color: transparent;
}
/* stylelint-enable selector-no-qualifying-type */
