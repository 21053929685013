/* slightly transparent fallback */
.frosty-background {
  background-color: rgba($white, 0.9);
}

/* if backdrop support: very transparent and blurred */
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .frosty-background {
    backdrop-filter: blur(30px);
    background-color: rgba($white, 0.75);
  }
}
