//
// Containers
//

.container,
.container-fluid,
%container-fluid {
  width: 100%;
  padding-right: var(--gutter-x, #{$grid-gutter-width});
  padding-left: var(--gutter-x, #{$grid-gutter-width});
  margin-right: auto;
  margin-left: auto;
}

//
// Responsive containers
// that are 100% wide until a breakpoint
//

@each $breakpoint, $container-max-width in $container-max-widths {
  @include mq-up($breakpoint) {
    $extend-breakpoint: true;
    %responsive-container-#{$breakpoint} {
      max-width: $container-max-width;
    }

    @each $name, $width in $grid-breakpoints {
      @if ($extend-breakpoint) {
        .container#{breakpoint-infix($name)} {
          @extend %responsive-container-#{$breakpoint};
        }

        @if ($breakpoint == $name) {
          $extend-breakpoint: false;
        }
      }
    }
  }

  .container-#{$breakpoint} {
    @extend %container-fluid;
  }
}

//
// Rows
//

.row {
  --gutter-x: #{$grid-gutter-width};
  --gutter-y: 0;

  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--gutter-y) * -1);
  margin-right: calc(var(--gutter-x) / -2);
  margin-left: calc(var(--gutter-x) / -2);

  > * {
    width: 100%;
    max-width: 100%;
    flex-shrink: 0;
    padding-right: calc(var(--gutter-x) / 2);
    padding-left: calc(var(--gutter-x) / 2);
    margin-top: var(--gutter-y);
  }
}

//
// Columns
//

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint);

  @include mq-up($breakpoint) {
    // Use `.col-{bp}` for equal-width flexbox columns
    .col#{$infix} {
      flex: 1 0 0%;
    }

    // Use `.col-{bp}-auto` for column sized based on the natural width of their content
    .col#{$infix}-auto {
      width: auto;
      flex: 0 0 auto;
    }

    // Use `.col-{bp}-{number}` for percentage number columns
    @for $i from 1 through $grid-columns {
      .col#{$infix}-#{$i} {
        @if $i {
          width: percentage(math.div($i, $grid-columns));
          flex: 0 0 auto;
        } @else {
          max-width: 100%;
          flex: 1 1 0;
        }
      }
    }

    // TODO: uncomment if column offsets become needed
    // `$grid-columns - 1` because offsetting by the width of an entire row isn't possible
    // @for $i from 0 through ($grid-columns - 1) {
    //   @if not($infix == '' and $i == 0) {
    //     // Avoid emitting useless .offset-0
    //     .offset#{$infix}-#{$i} {
    //       $num: $i / $grid-columns;
    //       margin-left: if($num == 0, 0, percentage($num));
    //     }
    //   }
    // }

    // Use `.g-{number}`, `.gx-{number}`, `.gy-{number}` to change spacing between the columns
    @each $key, $value in $spacers {
      .g#{$infix}-#{$key},
      .gx#{$infix}-#{$key} {
        --gutter-x: #{$value};
      }

      .g#{$infix}-#{$key},
      .gy#{$infix}-#{$key} {
        --gutter-y: #{$value};
      }
    }
  }
}
