//
// Improved normalize.scss
//

*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  @media (prefers-reduced-motion: no-preference) {
    scroll-behavior: smooth;
  }
}

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Prevent adjustments of font size after orientation changes in iOS.
// 4. Change the default tap highlight to be completely transparent in iOS.
body {
  margin: 0; // 1
  background-color: $body-bg; // 2
  color: $body-color;
  cursor: $cursor-default;
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  -webkit-tap-highlight-color: rgba($black, 0); // 4
  text-size-adjust: 100%; // 3
}

// Content grouping
//
// 1. Reset Firefox's gray color
// 2. Set correct height and prevent the `size` attribute to make the `hr` look like an input field
hr {
  border: 0;
  margin: 1rem 0;
  background-color: currentColor;
  color: inherit; // 1
  opacity: 0.25;
}

hr:not([size]) {
  height: $border-width; // 2
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: $font-weight-bold;
}

// 1. Undo browser default
dd {
  margin-bottom: 0.5rem;
  margin-left: 0; // 1
}

blockquote {
  margin: 0 0 1rem;
}

// Add the correct font weight in Chrome, Edge, and Safari
b,
strong {
  font-weight: $font-weight-bold;
}

// Add the correct font size in all browsers
small {
  font-size: $font-size-sm;
}

mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: $link-color;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    cursor: $cursor-pointer;
    text-decoration: $link-hover-decoration;
  }
}

// And undo these styles for placeholder links/named anchors (without href).
// It would be more straightforward to just use a[href] in previous block, but that
// causes specificity issues in many other styles that are too complex to fix.
// See https://github.com/twbs/bootstrap/issues/19402
a:not([href]):not([class]) {
  &,
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

pre,
code,
kbd,
samp {
  direction: ltr;
  font-family: $font-family-code;
  font-size: 1em;
  unicode-bidi: bidi-override;
}

// 1. Remove browser default top margin
// 2. Reset browser default of `1em` to use `rem`s
// 3. Don't allow content to break outside
pre {
  display: block;
  overflow: auto; // 3
  margin-top: 0; // 1
  margin-bottom: 1rem; // 2
  font-size: $font-size-sm;

  code {
    color: inherit;
    font-size: inherit;
    word-break: normal;
  }
}

code {
  color: #d63384;
  font-size: $font-size-sm;
  word-wrap: break-word;

  // Streamline the style when inside anchors to avoid broken underline and more
  a > & {
    color: inherit;
  }
}

kbd {
  padding: 0.2rem 0.4rem;
  background-color: $gray-900;
  border-radius: $border-radius-sm;
  color: $white;
  font-size: $font-size-sm;

  kbd {
    padding: 0;
    font-size: 1em;
    font-weight: $font-weight-bold;
  }
}

// Apply a consistent margin strategy (matches our type styles).
figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

// Prevent double borders
table {
  border-collapse: collapse;
  caption-side: bottom;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: $text-muted;
  text-align: left;
}

// 1. Matches default `<td>` alignment by inheriting `text-align`.
// 2. Fix alignment for Safari
th {
  text-align: inherit; // 1
  text-align: -webkit-match-parent; // 2
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-width: 0;
  border-style: solid;
  border-color: inherit;
}

// 1. Allow labels to use `margin` for spacing.
label {
  display: inline-block; // 1
}

// Remove the default `border-radius` that macOS Chrome adds.
// See https://github.com/twbs/bootstrap/issues/24093
button {
  border-radius: 0;
}

// Explicitly remove focus outline in Chromium when it shouldn't be
// visible (e.g. as result of mouse click or touch tap). It already
// should be doing this automatically, but seems to currently be
// confused and applies its very visible two-tone outline anyway.

button:focus:not(:focus-visible) {
  outline: 0;
}

// 1. Remove the margin in Firefox and Safari
input,
button,
select,
optgroup,
textarea {
  margin: 0; // 1
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

// Remove the inheritance of text transform in Firefox
button,
select {
  text-transform: none;
}
// Set the cursor for non-`<button>` buttons
// Details at https://github.com/twbs/bootstrap/pull/30562
[role='button'] {
  cursor: $cursor-pointer;
}

select {
  // Remove the inheritance of word-wrap in Safari.
  // See https://github.com/twbs/bootstrap/issues/24990
  word-wrap: normal;

  // Undo the opacity change from Chrome
  &:disabled {
    opacity: 1;
  }
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
// 3. Opinionated: add "hand" cursor to non-disabled button elements.
button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button; // 2

  &:not(:disabled) {
    cursor: $cursor-pointer; // 3
  }
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

// 1. Textareas should really only resize vertically so they don't break their (horizontal) containers.
textarea {
  resize: vertical; // 1
}

// 1. Browsers set a default `min-width: min-content;` on fieldsets,
//    unlike e.g. `<div>`s, which have `min-width: 0;` by default.
//    So we reset that to ensure fieldsets behave more like a standard block element.
//    See https://github.com/twbs/bootstrap/issues/12359
//    and https://html.spec.whatwg.org/multipage/#the-fieldset-and-legend-elements
// 2. Reset the default outline behavior of fieldsets so they don't affect page layout.
fieldset {
  min-width: 0; // 1
  padding: 0; // 2
  border: 0; // 2
  margin: 0; // 2
}

// 1. By using `float: left`, the legend will behave like a block element.
//    This way the border of a fieldset wraps around the legend if present.
// 2. Fix wrapping bug.
//    See https://github.com/twbs/bootstrap/issues/29712
legend {
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  float: left; // 1
  font-size: 1.5rem;
  line-height: inherit;

  + * {
    clear: left; // 2
  }
}

// 1. Correct the outline style in Safari.
// 2. This overrides the extra rounded corners on search inputs in iOS so that our
//    `.form-control` class can properly style them. Note that this cannot simply
//    be added to `.form-control` as it's not specific enough. For details, see
//    https://github.com/twbs/bootstrap/issues/11586.

[type='search'] {
  appearance: textfield; // 2
  outline-offset: -2px; // 1
}

// Correct element displays
output {
  display: inline-block;
}

// Remove border from iframe
iframe {
  border: 0;
}

// 1. Add the correct display in all browsers
summary {
  display: list-item; // 1
  cursor: $cursor-pointer;
}

// Add the correct vertical alignment in Chrome, Firefox, and Opera.
progress {
  vertical-align: baseline;
}

// Always hide an element with the `hidden` HTML attribute.
[hidden] {
  display: none !important;
}
