//
// Border utilities
//

.border {
  border: $border-width solid $border-color !important;
}

.border-top {
  border-top: $border-width solid $border-color !important;
}

.border-right {
  border-right: $border-width solid $border-color !important;
}

.border-bottom {
  border-bottom: $border-width solid $border-color !important;
}

.border-left {
  border-left: $border-width solid $border-color !important;
}

.border-rounded {
  border-radius: $border-radius !important;
}
