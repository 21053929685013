//
// Spacing utilities
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mq-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}-#{$size} {
          #{$prop}: $length !important;
        }
        .#{$abbrev}t#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-top: $length !important;
        }
        .#{$abbrev}r#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-right: $length !important;
        }
        .#{$abbrev}b#{$infix}-#{$size},
        .#{$abbrev}y#{$infix}-#{$size} {
          #{$prop}-bottom: $length !important;
        }
        .#{$abbrev}l#{$infix}-#{$size},
        .#{$abbrev}x#{$infix}-#{$size} {
          #{$prop}-left: $length !important;
        }
      }
    }

    // Some special margin utils
    .m#{$infix}-auto {
      margin: auto !important;
    }
    .mt#{$infix}-auto,
    .my#{$infix}-auto {
      margin-top: auto !important;
    }
    .mr#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-right: auto !important;
    }
    .mb#{$infix}-auto,
    .my#{$infix}-auto {
      margin-bottom: auto !important;
    }
    .ml#{$infix}-auto,
    .mx#{$infix}-auto {
      margin-left: auto !important;
    }
  }
}

// Negative margins
@each $size, $length in $spacers {
  .m-n#{$size} {
    margin: -$length !important;
  }
  .mt-n#{$size},
  .my-n#{$size} {
    margin-top: -$length !important;
  }
  .mr-n#{$size},
  .mx-n#{$size} {
    margin-right: -$length !important;
  }
  .mb-n#{$size},
  .my-n#{$size} {
    margin-bottom: -$length !important;
  }
  .ml-n#{$size},
  .mx-n#{$size} {
    margin-left: -$length !important;
  }
}
