//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: $font-family;
  font-weight: $font-weight;
  line-height: $line-height-sm;
}

h1,
.h1 {
  font-size: $font-size * 3; // 48px
}

h2,
.h2 {
  font-size: $font-size * 2.125; // 34px
}

h3,
.h3 {
  font-size: $font-size * 1.5; // 24px
}

h4,
.h4 {
  font-size: $font-size * 1.25; // 20px
}

h5,
h6,
.h5,
.h6 {
  font-size: $font-size;
}

//
// Others
//

.display-1 {
  font-size: 3rem; // 48px
  font-weight: $font-weight-bold;
  line-height: $line-height-sm;

  @include mq-up(sm) {
    font-size: 3.75rem; // 60px
  }
}

// Inline turns list items into inline-block
.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline__item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}
