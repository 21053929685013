//
// Variables
//

$alert-padding-x: 1.25rem;
$alert-padding-y: 0.75rem;

//
// Alert
//

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  border: $border-width solid transparent;
  margin-bottom: 1rem;
  border-radius: $border-radius;
}

.alert--closeable {
  padding-right: ($close-font-size + $alert-padding-x * 2);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: $alert-padding-y $alert-padding-x;
  }
}

//
// Variants
//

.alert--primary {
  border-color: $primary;
  background: $primary;
  color: $white;
}

.alert--secondary {
  border-color: $gray-400;
  background: $gray-300;
  color: $secondary;
}

.alert--success {
  border-color: $success;
  background: $success;
  color: $white;
}

.alert--info {
  border-color: $info;
  background: $info;
  color: $dark;
}

.alert--warning {
  border-color: $warning;
  background: $warning;
  color: $dark;
}

.alert--danger {
  border-color: $danger;
  background: $danger;
  color: $white;
}
