//
// Variables
//

$tooltip-arrow-size: 1rem;
$tooltip-box-shadow: 0 2px 4px rgba($black, 0.16);

//
// Tooltip
//

.tooltip {
  max-width: 200px;
  padding: 0.5rem 0.75rem;
  background: $gray-800;
  border-radius: $border-radius;
  box-shadow: $tooltip-box-shadow;
  color: $white;
  font-size: $font-size-xs;
}

.tooltip__arrow {
  z-index: $zindex-default-behind;

  &::after {
    position: absolute;
    display: block;
    width: $tooltip-arrow-size;
    height: $tooltip-arrow-size;
    background: $gray-800;
    border-radius: $border-radius;
    content: '';
    transform: rotate(45deg);
  }
}

//
// Placement
//

.tooltip[data-popper-placement^='top'] > .tooltip__arrow {
  &::after {
    top: -2px;
    margin-left: math.div(-$tooltip-arrow-size, 2);
  }
}

.tooltip[data-popper-placement^='bottom'] > .tooltip__arrow {
  &::after {
    bottom: $tooltip-arrow-size;
    margin-left: math.div(-$tooltip-arrow-size, 2);
  }
}

.tooltip[data-popper-placement^='left'] > .tooltip__arrow {
  right: math.div($tooltip-arrow-size, 1.8);
  margin-top: math.div(-$tooltip-arrow-size, 1.9);
}

.tooltip[data-popper-placement^='right'] > .tooltip__arrow {
  left: math.div(-$tooltip-arrow-size, 2.2);
  margin-top: math.div(-$tooltip-arrow-size, 2);
}
