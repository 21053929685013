//
// Clearfix utilities
//

.clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}
