//
// Variables
//

$switch-gap: 0.5rem;
$switch-track: $gray-300;
$switch-track-disabled: $gray-200;
$switch-thumb-color: $white;
$switch-thumb-disabled: $gray-100;
$switch-transition-duration: 0.2s;

//
// Switch
//

.switch--xxs {
  --switch-thumb-size: 6px;
  --switch-track-padding: 4px;
  --switch-adornment-size: 10px;
}

.switch--xs {
  --switch-thumb-size: 10px;
  --switch-track-padding: 4px;
  --switch-adornment-size: 10px;
}

.switch--sm {
  --switch-thumb-size: 15px;
  --switch-track-padding: 4px;
  --switch-adornment-size: 10px;
}

.switch--md {
  --switch-thumb-size: 20px;
  --switch-track-padding: 4px;
  --switch-adornment-size: 12px;
}

.switch--lg {
  --switch-thumb-size: 30px;
  --switch-track-padding: 6px;
  --switch-adornment-size: 20px;
}

.switch--none {
  --uncheckedColor: #{$switch-track};
  --checkedColor: #{$switch-track};
  --uncheckedThumbColor: #{$switch-thumb-color};
  --checkedThumbColor: #{$switch-thumb-color};
  --uncheckedBorderColor: #{$switch-track};
  --checkedBorderColor: #{$switch-track};
}

.switch--primary {
  --uncheckedColor: #{$switch-track};
  --checkedColor: #{$primary};
  --uncheckedThumbColor: #{$switch-thumb-color};
  --checkedThumbColor: #{$switch-thumb-color};
  --uncheckedBorderColor: #{$primary};
  --checkedBorderColor: #{$switch-track};
}

.switch--secondary {
  --uncheckedColor: #{$primary};
  --checkedColor: #{$white};
  --uncheckedThumbColor: #{$white};
  --checkedThumbColor: #{$primary};
  --uncheckedBorderColor: #{$gray-500};
  --checkedBorderColor: #{$white};
}

.switch {
  --border-size: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: math.div($switch-gap, 2);

  &.switch--top {
    flex-direction: column-reverse;

    .track {
      margin-top: $switch-gap;
    }
  }

  &.switch--start {
    flex-direction: row-reverse;

    .track {
      margin-left: $switch-gap;
    }
  }

  &.switch--bottom {
    flex-direction: column;

    .track {
      margin-bottom: $switch-gap;
    }
  }

  &.switch--end {
    flex-direction: unset;

    .track {
      margin-right: $switch-gap;
    }
  }

  &.switch--animate {
    .track,
    .track__thumb {
      transition: $switch-transition-duration;
    }
  }

  &.switch--border {
    --border-size: 2px;

    .track {
      border: var(--border-size) solid var(--uncheckedBorderColor);
      border-radius: calc(var(--switch-thumb-size) + var(--border-size));
    }

    .track__thumb {
      top: var(--border-size);
      left: var(--border-size);
    }

    input:checked + .track {
      border-color: var(--checkedBorderColor);
    }
  }

  input {
    display: none;
  }

  /* stylelint-disable selector-max-compound-selectors */
  /* stylelint-disable selector-no-qualifying-type */
  input:disabled + .track,
  input[readonly]:read-only + .track {
    background-color: $switch-track-disabled;

    .track__thumb {
      background-color: $switch-thumb-disabled;
    }
  }

  input:checked + .track {
    background-color: var(--checkedColor);

    .track__thumb {
      background-color: var(--checkedThumbColor);
      transform: translateX(var(--switch-thumb-size));
    }
  }
}

.track {
  position: relative;
  width: calc(var(--switch-thumb-size) * 2 + var(--switch-track-padding) * 2);
  height: calc(var(--switch-thumb-size) + var(--switch-track-padding) * 2);
  padding: var(--switch-track-padding);
  background-color: var(--uncheckedColor);
  border-radius: var(--switch-thumb-size);
  cursor: $cursor-pointer;
}

.track__adornment {
  position: absolute;
  display: flex;
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  align-items: center;
  justify-content: center;
  user-select: none;

  &.track__adornment--start {
    left: var(--switch-track-padding);
  }

  &.track__adornment--end {
    right: var(--switch-track-padding);
  }

  * {
    width: var(--switch-adornment-size);
    height: var(--switch-adornment-size);
    font-size: var(--switch-adornment-size);
  }
}

.track__thumb {
  position: absolute;
  width: var(--switch-thumb-size);
  height: var(--switch-thumb-size);
  background-color: var(--uncheckedThumbColor);
  border-radius: 50%;
  box-shadow: 1px 2px 3px rgba($black, 0.02);
}
