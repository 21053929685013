//
// Variables
//

$btn-padding-y: 0.625rem;
$btn-padding-x: 0.75rem;

$btn-padding-y-sm: 0.25rem;
$btn-padding-x-sm: 0.75rem;

$btn-padding-y-lg: 0.5rem;
$btn-padding-x-lg: 1rem;

//
// Base styles
//

.btn {
  display: inline-block;
  min-width: 6rem;
  padding: $btn-padding-y $btn-padding-x;
  border: $border-width solid transparent;
  background-color: transparent;
  border-radius: $border-radius-pill;
  color: $body-color;
  cursor: $cursor-pointer;
  font-size: $font-size-sm;
  font-weight: $font-weight;
  line-height: $line-height;
  text-align: center;
  text-decoration: none;
  transition: box-shadow 0.15s ease-in-out;
  user-select: none;
  vertical-align: middle;

  &:hover {
    color: $body-color;
  }

  &:focus {
    box-shadow: $input-focus-box-shadow;
    outline: 0;
  }

  &:disabled,
  &.btn--disabled,
  fieldset:disabled & {
    box-shadow: none;
    opacity: 0.65;
    pointer-events: none;
  }
}

//
// Variants
//

.btn--primary {
  border-color: $primary;
  background-color: $primary;
  color: $white;
  transition: box-shadow 0.15s ease-in-out, transform 0.1s ease-in-out;

  &:hover {
    background-color: $gray-900;
    color: $white;
  }

  &:focus {
    box-shadow: 0 0 0 $input-focus-width rgba(mix($white, $black, 15%), 0.5);
  }
}

.btn--secondary {
  border-color: $gray-200;
  background-color: $gray-200;
}

.btn--tertiary {
  border-color: $gray-300;
  background-color: $white;
}

.btn--naked {
  min-width: auto;
  padding: 0;
  border-color: transparent;
  border-radius: 0;
}

.btn--link {
  min-width: auto;
  padding: 0;
  color: $link-color;
  font-weight: $font-weight;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }

  &:focus {
    box-shadow: none;
    text-decoration: $link-hover-decoration;
  }

  &:disabled,
  &.disabled {
    color: $text-muted;
  }
}

.btn--loading {
  position: relative;
  color: transparent !important;
  pointer-events: none;

  &::after {
    @extend %spinner;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: math.div(-$spinner-size, 2);
    margin-left: math.div(-$spinner-size, 2);
    color: $white;
    content: '';

    @at-root {
      .btn--secondary#{&} {
        color: $body-color;
      }
    }
  }
}

.btn--icon {
  display: inline-flex;
  width: 2.5rem;
  min-width: auto;
  min-height: 2.5rem;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 50%;
  cursor: $cursor-pointer;
  user-select: none;

  .svgr-icon {
    top: unset;
  }

  // Add empty content to use line-height as height of content to align with text buttons
  &::before {
    content: '\feff';
  }
}

//
// Button Sizes
//

.btn--sm {
  padding: $btn-padding-y-sm $btn-padding-x-sm;
  font-size: $font-size-sm;

  &.btn--icon {
    width: 2rem;
    height: 2rem;
    padding: $btn-padding-x-sm $btn-padding-x-sm;
  }
}

.btn--lg {
  padding: $btn-padding-y-lg $btn-padding-x-lg;
  font-size: $font-size-lg;

  &.btn--icon {
    width: 3rem;
    height: 3rem;
    padding: $btn-padding-x-lg $btn-padding-x-lg;
  }
}
