// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ('<', '%3c'),
  ('>', '%3e'),
  ('#', '%23'),
  ('(', '%28'),
  (')', '%29')
);

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }

  @return $string;
}

// See https://codepen.io/kevinweber/pen/dXWoRw
//
// Requires the use of quotes around data URIs.

@function escape-svg($string) {
  @if str-index($string, 'data:image/svg+xml') {
    @each $char, $encoded in $escaped-characters {
      // Do not escape the url brackets
      @if str-index($string, 'url(') == 1 {
        $string: url('#{str-replace(str-slice($string, 6, -3), $char, $encoded)}');
      } @else {
        $string: str-replace($string, $char, $encoded);
      }
    }
  }

  @return $string;
}

@mixin fill-background-image($url, $color) {
  $base-color: str-slice(inspect($color), 2);
  background-image: unquote('url("' + $url + '?fill=%23' + $base-color + '")');
}

@mixin stroke-background-image($url, $color) {
  $base-color: str-slice(inspect($color), 2);
  background-image: unquote(
    'url("' + $url + '?stroke=%23' + $base-color + '")'
  );
}

@mixin btn-focus-visible-border {
  .btn:focus {
    box-shadow: none;
  }

  .btn:focus-visible {
    border-radius: 20px;
    outline: 3px solid $blue-paradise;
  }
}
