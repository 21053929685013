//
// Display utilities
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mq-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    .d#{$infix}-none {
      display: none !important;
    }
    .d#{$infix}-inline {
      display: inline !important;
    }
    .d#{$infix}-inline-block {
      display: inline-block !important;
    }
    .d#{$infix}-block {
      display: block !important;
    }
    .d#{$infix}-grid {
      display: grid !important;
    }
    .d#{$infix}-flex {
      display: flex !important;
    }
    .d#{$infix}-inline-flex {
      display: inline-flex !important;
    }
  }
}
