//
// Colors
//

// Post-it Figma Design System
$candy-apple-red: #f05237;
$vital-orange: #ff9350;
$sunnyside: #ffce2d;
$lucky-green: #79c47a;
$sea-glass: #3aa8b8;
$moonstone: #91a0e2;
$iris-infusion: #c39acb;
$power-pink: #fa6d9c;
$guava: #ff8d90;
$papaya-fizz: #ffaa8f;
$citron: #eddb4c;
$limeade: #b7d664;
$aqua-splash: #66d7de;
$blue-paradise: #56c4e8;
$orchid-frost: #dacedb;
$tropical-pink: #faa5dd;
$pebble-gray: #bdb5af;
$canary-yellow: #faf19d;
$acid-lime: #dfe561;
$fresh-mint: #b9e7dd;
$washed-denim: #99c6ee;
$positively-pink: #ffb9c8;
$pink-salt: #f2ddde;
$first-snow: #f1f3f4;

// Bootstrap mapping
$blue: $washed-denim;
$indigo: $iris-infusion;
$purple: $iris-infusion;
$pink: $positively-pink;
$red: $candy-apple-red;
$orange: $vital-orange;
$yellow: $sunnyside;
$green: $limeade;
$teal: $aqua-splash;
$cyan: $blue-paradise;

// Grays
// See: https://coolors.co/1e1e1e-393e46-4c5769-63708c-949eb0-cccccc-e6e6e6-eeeeee-f6f8fa
$white: #fff;
$gray-100: #f6f8fa;
$gray-200: #eee;
$gray-300: #e6e6e6;
$gray-400: #ccc;
$gray-500: #949eb0;
$gray-600: #63708c;
$gray-700: #4c5769;
$gray-800: #393e46;
$gray-900: #1e1e1e;
$black: #000;

// Brand
$primary: $gray-800;
$secondary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;

// General
$body-bg: $white;
$body-color: $gray-800;
$border-color: $gray-300;
$link-color: $primary;
$link-hover-color: darken($primary, 20%);
$text-muted: $gray-500;
$active-color: $white;
$active-bg: $black;

$cursor-candy-red: $candy-apple-red;
$cursor-vital-orange: $vital-orange;
$cursor-sunny-side: $sunnyside;
$cursor-limeade: $limeade;
$cursor-lucky-green: $lucky-green;
$cursor-blue-paradise: $blue-paradise;
$cursor-sea-glass: $sea-glass;
$cursor-moonstone: $moonstone;
$cursor-iris-infusion: $iris-infusion;
$cursor-power-pink: $power-pink;

//
// Typography
//

$font-family: 'cera-pro', system-ui, -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-family-code: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
  'Courier New', monospace;

$font-size: 1rem; // 16px
$font-size-xxs: $font-size * 0.5; // 8px
$font-size-xs: $font-size * 0.75; // 12px
$font-size-sm: $font-size * 0.875; // 14px
$font-size-lg: $font-size * 1.25; // 20px
$font-size-xl: $font-size * 1.5; // 24px
$font-size-xxl: $font-size * 2; // 32px

$font-weight-light: 300;
$font-weight: 400;
$font-weight-bold: 700;

$line-height: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$link-decoration: underline;
$link-hover-decoration: null;

//
// Grid
// https://github.com/twbs/bootstrap/issues/21333
//

$grid-columns: 12;
$grid-gutter-width: 1.5rem;

// All values should be multiple of $grid-columns
$grid-breakpoints: (
  xs: 0,
  sm: 600px,
  md: 960px,
  lg: 1272px,
  xl: 1920px
);

// All values should be multiple of $grid-columns
$container-max-widths: (
  sm: 564px,
  md: 924px,
  lg: 1236px,
  xl: 1884px
);

//
// Z-index list
//

$zindex-modal: 999;
$zindex-stretched-link: 1;
$zindex-default-infront: 1;
$zindex-default-behind: -1;
$zindex-board-canvas: 2;
$zindex-board-canvas-ui: 3;
$zindex-board-ui: 4;

//
// Components
//

$spacer: 1rem;
$spacers: (
  0: 0,
  1: math.div($spacer, 4),
  2: math.div($spacer, 2),
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 3
);

$border-width: 1px;
$border-radius: 0.25rem; // 4px
$border-radius-sm: 0.125rem; // 2px
$border-radius-lg: 0.375rem; // 6px
$border-radius-pill: 50em;

$input-focus-width: 0.25rem;
$input-focus-border-color: mix(white, $active-bg, 50%);
$input-focus-box-shadow: 0 0 0 $input-focus-width rgba($active-bg, 0.25);

$spinner-size: 1rem;
$spinner-size-lg: 1.5rem;

$spinner-plane-size: 0.75rem;
$spinner-plane-size-lg: 1rem;

$close-font-size: $font-size * 1.5;

//
// Cursors
//

$cursor-default: url('assets/icons/cursors/default.svg') 6 6, default;
$cursor-pointer: url('assets/icons/cursors/pointer.svg') 10 6, pointer;
