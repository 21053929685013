//
// Variables
//

$input-padding-y: 0.75rem;
$input-padding-x: 1rem;
$input-font-size: $font-size-sm;
$input-border-width: $border-width;
$input-line-height: $line-height;

$input-padding-y-sm: 0.25rem;
$input-padding-x-sm: 0.75rem;
$input-font-size-sm: $font-size-sm;

$input-padding-y-lg: 0.5rem;
$input-padding-x-lg: 1rem;
$input-font-size-lg: $font-size-lg;

$input-height: calc(
  #{$input-line-height} * #{$input-font-size} + #{$input-padding-y} * 2 + #{$input-border-width} *
    2
);
$input-height-sm: calc(
  #{$input-line-height} * #{$input-font-size} + #{$input-padding-y-sm} * 2 + #{$input-border-width} *
    2
);
$input-height-lg: calc(
  #{$input-line-height} * #{$input-font-size} + #{$input-padding-y-lg} * 2 + #{$input-border-width} *
    2
);

$input-check-width: 1em;
$input-check-checkbox-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$active-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$input-check-radio-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-5 -5 10 10'><circle r='3' fill='#{$active-bg}'/></svg>");

//
// Labels
//

.form-label {
  margin-bottom: 0.5rem;
  color: $gray-700;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

// Horizontal forms and inline forms
// When you need the label to align
.col-form-label {
  padding-top: calc($input-padding-y + $input-border-width);
  padding-bottom: calc($input-padding-y + $input-border-width);
  margin-bottom: 0; // Override the `<legend>` default
  font-size: inherit; // Override the `<legend>` default
  line-height: $input-line-height;
}

.col-form-label--sm {
  padding-top: calc($input-padding-y-sm + $input-border-width);
  padding-bottom: calc($input-padding-y-sm + $input-border-width);
  font-size: $input-font-size-sm;
}

.col-form-label--lg {
  padding-top: calc($input-padding-y-lg + $input-border-width);
  padding-bottom: calc($input-padding-y-lg + $input-border-width);
  font-size: $input-font-size-lg;
}

//
// Form text
//

.form-text {
  margin-top: 0.25rem;
  color: $text-muted;
  font-size: $font-size-sm;
}

//
// Form feedback
//

.form-feedback {
  display: none;
  margin-top: 0.25rem;
  font-size: $font-size-sm;
}

//
// Form control
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  border: $input-border-width solid $border-color;
  appearance: none; // Fix appearance for date inputs in Safari
  background-clip: padding-box;
  background-color: $gray-100;
  border-radius: $border-radius-lg;
  color: $body-color;
  font-size: $input-font-size;
  font-weight: $font-weight;
  line-height: $input-line-height;

  &:focus {
    border-color: $input-focus-border-color;
    background-color: $gray-100;
    color: $body-color;
    outline: 0;
  }

  &::placeholder {
    color: $gray-600;
    // Override Firefox's unusual default opacity
    // see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  &:disabled,
  &[readonly] {
    background-color: $gray-200;
    // iOS fix for unreadable disabled content
    // see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

.form-control--sm {
  min-height: $input-height-sm;
  padding: $input-padding-y-sm $input-padding-x-sm;
  font-size: $input-font-size-sm;
}

.form-control--lg {
  min-height: $input-height-lg;
  padding: $input-padding-y-lg $input-padding-x-lg;
  font-size: $input-font-size-lg;
}

//
// Readonly controls as plain text
//

.form-control--plaintext,
.form-control--plaintext:focus,
.form-control--plaintext:disabled,
.form-control--plaintext[readonly] {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  border: solid transparent;
  border-width: $input-border-width 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  background-color: transparent;
  color: $body-color;
  line-height: $input-line-height;

  &.form-control--sm,
  &.form-control--lg {
    padding-right: 0;
    padding-left: 0;
  }
}

//
// Text area
//

// Make sure textareas don't shrink too much when resized
// https://github.com/twbs/bootstrap/pull/29124

/* stylelint-disable selector-no-qualifying-type */
textarea {
  &.form-control {
    min-height: $input-height;
  }

  &.form-control--sm {
    min-height: $input-height-sm;
  }

  &.form-control--lg {
    min-height: $input-height-lg;
  }
}
/* stylelint-enable selector-no-qualifying-type */

//
// Search
//

.form-control[type='search'] {
  @include fill-background-image('assets/icons/search.svg', $gray-500);
  padding-left: 2.5rem;
  background-position: left 1rem center;
  background-repeat: no-repeat;
}

//
// Password
//

.form-password {
  position: relative;
  font-family: $font-family-code;

  .form-control {
    padding-right: 3.5rem;
  }

  .btn-password-reveal {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    height: $input-height;
    align-items: center;
    padding: 0 1rem;
    border-bottom-right-radius: $border-radius;
    border-top-right-radius: $border-radius;
    color: $gray-600;
    font-size: $font-size-xl;
  }
}

//
// Form group
//

.form-group {
  width: 100%;
  margin-bottom: 1.5rem;
}

//
// Check/radio
//

.form-check {
  display: block;
  min-height: $font-size * $input-line-height;
  padding-left: $input-check-width + 0.5em;
  margin-bottom: 0.125rem;
}

.form-check__label {
  cursor: pointer;
  font-size: $input-font-size;
}

.form-check__input {
  width: $input-check-width;
  height: $input-check-width;
  border: $input-border-width solid rgba($black, 0.25);
  margin-top: math.div($input-line-height - $input-check-width, 2);
  margin-left: ($input-check-width + 0.5em) * -1;
  appearance: none;
  background-color: $gray-100;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  float: left;
  print-color-adjust: exact; // Keep themed appearance for print
  vertical-align: top;

  &[type='checkbox'] {
    border-radius: 0.25em;
  }

  &[type='radio'] {
    border-radius: 50%;
  }

  &:active {
    filter: brightness(90%);
  }

  &:focus {
    border-color: $input-focus-border-color;
    box-shadow: $input-focus-box-shadow;
    outline: 0;
  }

  &:checked {
    &[type='checkbox'] {
      border-color: $active-bg;
      background-color: $active-bg;
      background-image: escape-svg($input-check-checkbox-image);
    }

    &[type='radio'] {
      border-color: $active-bg;
      background-color: $active-color;
      background-image: escape-svg($input-check-radio-image);
    }
  }

  &:disabled {
    filter: none;
    opacity: 0.5;
    pointer-events: none;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: 0.5;
    }
  }
}

.form-check--inline {
  display: inline-block;
  margin-right: 1rem;
}

//
// Validation
//

.is-valid,
.is-invalid {
  .form-feedback {
    display: block;
  }
}

.is-invalid {
  .form-feedback {
    color: $danger;
  }

  .form-control {
    border-color: $danger;
  }
}

.is-valid {
  .form-feedback {
    color: $success;
  }

  .form-control {
    border-color: $success;
  }
}
