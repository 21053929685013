//
// cera-pro
//

@font-face {
  font-display: swap;
  font-family: 'cera-pro';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('assets/fonts/CeraPro-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'cera-pro';
  font-style: normal;
  font-weight: $font-weight;
  src: url('assets/fonts/CeraPro-Medium.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'cera-pro';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('assets/fonts/CeraPro-Bold.ttf') format('woff2');
}

//
// kalam
//

@font-face {
  font-display: swap;
  font-family: 'kalam';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('assets/fonts/Kalam-Light-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: 'kalam';
  font-style: normal;
  font-weight: $font-weight-light;
  src: url('assets/fonts/Kalam-Light-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: swap;
  font-family: 'kalam';
  font-style: normal;
  font-weight: $font-weight;
  src: url('assets/fonts/Kalam-Regular-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: 'kalam';
  font-style: normal;
  font-weight: $font-weight;
  src: url('assets/fonts/Kalam-Regular-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@font-face {
  font-display: swap;
  font-family: 'kalam';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('assets/fonts/Kalam-Bold-latin-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
@font-face {
  font-display: swap;
  font-family: 'kalam';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('assets/fonts/Kalam-Bold-latin.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

//
// Permanent Marker
//

@font-face {
  font-display: swap;
  font-family: 'permanent-marker';
  font-style: normal;
  font-weight: $font-weight;
  src: url('assets/fonts/PermanentMarker-Regular.ttf') format('truetype');
}

//
// Montserrat
//
@font-face {
  font-display: swap;
  font-family: 'roboto-slab';
  font-style: normal;
  font-weight: $font-weight;
  src: url('assets/fonts/RobotoSlab-VariableFont_wght.ttf') format('truetype');
}
//
// Noto-sans
//

@font-face {
  font-display: swap;
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: $font-weight;
  src: url('assets/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'noto-sans';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('assets/fonts/NotoSans-Bold.ttf') format('truetype');
}
