//
// Accordion
//

.accordion__title {
  display: flex;
  align-items: center;
  cursor: $cursor-pointer;

  &:not(:first-child) {
    border-top: $border-width solid $border-color;
  }
}

.accordion__chevron {
  width: 0.75rem;
  margin-left: 0.75rem;
  transform: rotate(180deg);

  .accordion__title--active & {
    transform: rotate(0);
  }
}

//
// Variants
//

.accordion--fill {
  border: $border-width solid $border-color;
  border-radius: $border-radius;

  .accordion__title {
    padding: 0.75rem 1rem;

    &:first-child {
      border-top-left-radius: $border-radius;
      border-top-right-radius: $border-radius;
    }

    &--active {
      border-bottom: $border-width solid $border-color;
      background-color: $gray-100;
      font-weight: $font-weight-bold;
    }
  }

  .accordion__content {
    padding: 0.75rem 1rem;
  }

  .accordion__chevron {
    margin-left: auto;
  }
}

.accordion--naked {
  .accordion__title {
    border: 0;
  }
}
