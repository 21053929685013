//
// Images
//

.img-fluid {
  max-width: 100%;
  height: auto;
}

//
// Figures
//

.figure {
  display: inline-block;
}

.figure__img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure__caption {
  color: $gray-600;
  font-size: $font-size-sm;
}
