//
// Variables
//

$spinner-border: 2px;
$spinner-border-lg: 3px;

//
// Spinner
//

%spinner {
  width: $spinner-size;
  height: $spinner-size;
  border: $spinner-border solid currentColor;
  border-right-color: transparent;
  animation: 0.75s linear infinite spinner-border;
  border-radius: 50%;
  vertical-align: -0.125em;
}

%plane {
  width: $spinner-plane-size;
  min-width: $spinner-plane-size;
  height: $spinner-plane-size;

  animation: rotateplane 1.2s infinite ease-in-out,
    changebackground 2.4s infinite ease-in-out;
  background-color: $cursor-lucky-green;
}

.spinner {
  display: inline-block;
}

.spinner__round {
  @extend %spinner;

  &--lg {
    width: $spinner-size-lg;
    height: $spinner-size-lg;
    border-width: $spinner-border-lg;
  }
}

.spinner__plane {
  @extend %plane;

  &--lg {
    width: $spinner-plane-size-lg;
    min-width: $spinner-plane-size-lg;
    height: $spinner-plane-size-lg;
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

/** rotating plane */

/** Original animation can be found here: https://tobiasahlin.com/spinkit/ */
@keyframes rotateplane {
  0% {
    transform: perspective(180px);
  }

  50% {
    transform: perspective(180px) rotateY(180deg);
  }

  100% {
    transform: perspective(180px) rotateY(180deg) rotateX(180deg);
  }
}

/**
* The background animation starts "midway" a rotation (when we just have a square)
* That is why we have to start the background animation in the middle of the green animation.
*/
@keyframes changebackground {
  0% {
    background-color: $cursor-lucky-green;
  }

  12% {
    background-color: $cursor-lucky-green;
  }

  13% {
    background-color: $cursor-moonstone;
  }

  37% {
    background-color: $cursor-moonstone;
  }

  38% {
    background-color: $cursor-sunny-side;
  }

  62% {
    background-color: $cursor-sunny-side;
  }

  63% {
    background-color: $papaya-fizz;
  }

  87% {
    background-color: $papaya-fizz;
  }

  88% {
    background-color: $cursor-lucky-green;
  }
}
