//
// Float utilities
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mq-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    .f#{$infix}-left {
      float: left !important;
    }
    .f#{$infix}-right {
      float: right !important;
    }
    .f#{$infix}-none {
      float: none !important;
    }
  }
}
