//
// Text whitespace
//

.t-justify {
  text-align: justify !important;
}

.t-nowrap {
  white-space: nowrap !important;
}

.t-pre {
  white-space: pre-line !important;
}

.t-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

//
// Text alignment
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include mq-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint);

    .t#{$infix}-left {
      text-align: left !important;
    }
    .t#{$infix}-right {
      text-align: right !important;
    }
    .t#{$infix}-center {
      text-align: center !important;
    }
  }
}

//
// Text transformation
//

.t-lower {
  text-transform: lowercase !important;
}

.t-upper {
  text-transform: uppercase !important;
}

.t-caps {
  text-transform: capitalize !important;
}

//
// Text sizes
//

.t-xs {
  font-size: $font-size-xs !important;
}

.t-sm {
  font-size: $font-size-sm !important;
}

.t-md {
  font-size: $font-size !important;
}

.t-lg {
  font-size: $font-size-lg !important;
}

.t-xl {
  font-size: $font-size-xl !important;
}

.t-xxl {
  font-size: $font-size-xxl !important;
}

//
// Text weights and italics
//

.t-light {
  font-weight: $font-weight-light !important;
}

.t-normal {
  font-weight: $font-weight !important;
}

.t-bold {
  font-weight: $font-weight-bold !important;
}

.t-italic {
  font-style: italic !important;
}

//
// Text fonts
//

.t-serif {
  font-family: $font-family !important;
}

.t-mono {
  font-family: $font-family-code !important;
}

//
// Text decoration
//

.t-decoration-none {
  text-decoration: none !important;
}

.t-underline {
  text-decoration: underline !important;
}

.t-link {
  color: $link-color;
  cursor: $cursor-pointer;
  text-decoration: $link-decoration;

  &:hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

//
// Colors
//

.t-primary {
  color: $primary !important;
}

.t-secondary {
  color: $secondary !important;
}

.t-success {
  color: $success !important;
}

.t-info {
  color: $info !important;
}

.t-warning {
  color: $warning !important;
}

.t-danger {
  color: $danger !important;
}

.t-white {
  color: $white !important;
}

.t-gray-100 {
  color: $gray-100 !important;
}

.t-gray-200 {
  color: $gray-200 !important;
}

.t-gray-300 {
  color: $gray-300 !important;
}

.t-gray-400 {
  color: $gray-400 !important;
}

.t-gray-500 {
  color: $gray-500 !important;
}

.t-gray-600 {
  color: $gray-600 !important;
}

.t-gray-700 {
  color: $gray-700 !important;
}

.t-gray-800 {
  color: $gray-800 !important;
}

.t-gray-900 {
  color: $gray-900 !important;
}

.t-body {
  color: $body-color !important;
}

.t-muted {
  color: $text-muted !important;
}

.t-inherit {
  color: inherit !important;
}
