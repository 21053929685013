//
// Roboto
//

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: $font-weight;
  src: url('../fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: $font-weight-bold;
  src: url('../fonts/Roboto-Medium.ttf') format('truetype');
}
